/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Image, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Advokátní kancelář Solers legal s.r.o."}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pt--60" style={{"backgroundColor":"#f9f315","paddingBottom":46}} name={"qxcwyon2o"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Upozornění na podvodné SMS"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":704}} content={"Vážení klienti,<br>chtěli bychom Vás informovat, že došlo ke zneužití jména naší společnosti Solers legal.\n \nPokud vám přišla SMS z tel. +420733203155, že máte zaplatit pokutu za překročení rychlosti a kliknout na odkaz v SMS, podepsáno AK Solers Legal, rozhodně tak prosím nečiňte, jedná se o podvodné jednání.<br>Naše společnost nerozesílá žádné SMS.<br>V případě, že si nebudete jistí, obraťte se na naši kancelář +420 605 196 955.<br><br>Děkujeme, tým Solers legal s.r.o.<br>27. 8. 2024<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-16qqohg css-42e4bw --left --full pl--16" name={"uvod"} fullscreen={true} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17530/348b40c58bcb4567b045b1a0e5972ce4_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17530/348b40c58bcb4567b045b1a0e5972ce4_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17530/348b40c58bcb4567b045b1a0e5972ce4_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17530/348b40c58bcb4567b045b1a0e5972ce4_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17530/348b40c58bcb4567b045b1a0e5972ce4_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17530/348b40c58bcb4567b045b1a0e5972ce4_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17530/348b40c58bcb4567b045b1a0e5972ce4_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17530/348b40c58bcb4567b045b1a0e5972ce4_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":1360}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3 pl--0" anim={"7"} animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center fs--86" style={{"maxWidth":""}} content={"<span style=\"color: white;\">Profesionální právní servis<br>pro střední Moravu<br></span>"}>
              </Title>

              <Button className="btn-box btn-box--pbtn4 btn-box--shape5 ff--2 fs--20 ls--002 lh--1 mt--30" use={"page"} href={"/#kontakt"} content={"Domluvit úvodní konzultaci"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"rk5swj56s8f"} style={{"marginTop":0,"paddingTop":80}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Svěřte své právní problémy do péče advokátů ze Solers legal s.r.o.<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"30eg56se5hr"} style={{"marginTop":0,"paddingTop":121}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Vladislav Kutěj<br>advokát<br>"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":399}} content={"Pochází z Hranic.<br>Vystudoval Právnickou fakultu Univerzity Karlovy.<br>Je zakládajícím společníkem pražské advokátní kanceláře Solers legal s.r.o. a vedoucím jejího trestněprávního oddělení.<br><br>Před založením Solers legal s.r.o. a její hranické pobočky působil v přední pražské advokátní kanceláři se specializací na trestní právo.<br>Je členem České advokátní komory a Unie obhájců. Je zapsán v registru poskytovatelů pomoci obětem trestných činů.<br><br>Ve své více než desetileté praxi se věnuje zejména trestnímu právu, a to jako trestní obhájce fyzických či právnických osob, tak jako zmocněnec poškozených a obětí trestných činů.<br><br>Vedle trestního práva poskytuje také strategické právní poradenství řadě obchodních korporací napříč Českou republikou.<br><br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17530/0d71cc8d94544b8fa2e23817c83f569f_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":603}} srcSet={"https://cdn.swbpg.com/t/17530/0d71cc8d94544b8fa2e23817c83f569f_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/17530/0d71cc8d94544b8fa2e23817c83f569f_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/17530/0d71cc8d94544b8fa2e23817c83f569f_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/17530/0d71cc8d94544b8fa2e23817c83f569f_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image className="pt--10" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17530/b439a90fb537487e88ba28d77953bd6c_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":604}} srcSet={"https://cdn.swbpg.com/t/17530/b439a90fb537487e88ba28d77953bd6c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17530/b439a90fb537487e88ba28d77953bd6c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17530/b439a90fb537487e88ba28d77953bd6c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17530/b439a90fb537487e88ba28d77953bd6c_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"<br><br>David Havel<br>advokát<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"Pochází z Nového Jičína.<br>Vystudoval Právnickou fakultu Univerzity Karlovy.<br>Je členem České advokátní komory zapojeným rovněž do projektu Advokáti do škol.<br><br>Před nástupem na pozici vedoucího advokáta hranické pobočky Solers legal s.r.o. působil v menších pražských advokátních kancelářích se zaměřením na generální praxi a následně na Obvodním soudě pro Prahu 5 a Obvodním soudě pro Prahu 8, kde získal zkušenosti na všech úsecích prvostupňového soudnictví.<br>Z justice si do advokacie odnesl mnoho cenných a praktických zkušeností o správném, efektivním a hospodárném fungování zejména občanských a opatrovnických řízení, které nyní v plném rozsahu využívá ve prospěch našich klientů při hájení jejich práv.<br><br>Ve své více než desetileté praxi se zaměřuje na občanské právo, zejména pak civilní právo sporné i nesporné. V rámci generální advokátní praxe však zastupuje zájmy našich klientů rovněž v trestních a správních řízeních.&nbsp;<br><br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"4ps594jccme"} style={{"paddingTop":0,"paddingBottom":139}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box ff--1 fs--30" style={{"maxWidth":956}} content={"<span style=\"color: var(--color-custom-2);\">Při poskytování právních služeb úzce spolupracujeme také s našimi kolegy z pražské centrály advokátní kanceláře Solers legal s.r.o.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--08 pt--50" name={"sluzby"} style={{"backgroundColor":"rgba(64, 64, 64, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--color-custom-1--95);\">S čím Vám náš tým zkušených advokátů pomůže?</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--50 pt--40" name={"sluzby1"} style={{"backgroundColor":"rgba(64, 64, 64, 1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3 pb--10 pt--10" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Smlouvy</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Právní pomoc při vyjednávání o podmínkách smluv, jejich tvorbě a revizích.<br>Smlouvy o dílo, zprostředkovatelské smlouvy, nájemní smlouvy, developerské smlouvy, kupní smlouvy, darovací smlouvy, rámcové smlouvy a smlouvy o smlouvách budoucích.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"4"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Nemovitosti</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Komplexní právní servis při převodu vlastnického práva k nemovitostem (vyjednávání podmínek, sepis příslušných smluv, úschova kupní ceny, příprava a podání návrhu na vklad do katastru nemovitostí, finalizace převodu vlastnického práva).<br>Zastupování před správními úřady.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Rodinné právo</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Komplexní právní servis a poradenství ve všech věcech týkajících se péče o nezletilé děti, vypořádání rozvodu manželství, zajištění stanovení a eventuální vymáhání dluženého výživného na nezletilé děti či nezaopatřenou matku.<br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3 pb--10 pt--10" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Obchodní právo</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Komplexní právní servis pro obchodní korporace.<br>Zakládání a vznik právnických osob, příprava a právní pomoc s konáním zasedání a rozhodování orgánů obchodní korporace, zajištění realizace změn zápisů v obchodním rejstříku či v evidenci skutečných majitelů, právní audity (due dilligence) obchodních společností, nastavení korporátních procesů, zastoupení klienta při jednání s notářem.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"4"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Občanské právo</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Právní pomoc a zastoupení klientů ve věcech vedených u civilních soudů, a to na všech instancích včetně Ústavního soudu. Právní servis v případě exekuce a insolvence. Komplexní právní poradenství související s bytovým právem, duševním vlastnictví a vypořádání spoluvlastnictví.<br>Vymáhání pohledávek.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Trestní právo</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Obhajoba klientů v řízení před orgány Policie České republiky a v řízení před soudem (včetně řízení o dovolání u Nejvyššího soudu a řízení o ústavní stížnosti před Ústavním soudem). Zastupování poškozených a obětí trestné činnosti. Uplatňování práv poškozených na náhradu škody způsobené trestnou činností. Právní pomoc při podání vysvětlení, účasti na výslechu a při zajištění majetku."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3 pb--10 pt--10" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Dědictví</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Komplexní právní servis v řízení o pozůstalosti. Jednání s notářem, asistence při dohledání obsahu pozůstalosti, zastupování v souvisejících soudních sporech."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"4"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Správní právo</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Komplexní právní servis týkající se stavebního řízení. Zastupování územně samosprávných celků, právo životního prostředí a odpadové hospodářství.<br>Cizinecké a azylové právo.<br>Zastupování klientů v řízení před správními orgány a v souvisejících řízeních před správními soudy, a to včetně řízení u Nejvyššího správního soudu.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Přestupky</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Komplexní právní servis a obhajoba v řízení o přestupcích nejen na úseku dopravy."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"cenik"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Kde nás najdete?"}>
              </Title>

              <Text className="text-box" content={"Sídlíme v Hranicích v Kunzově vile na Třídě 1. máje, č.p. 328.<br>Pro sjednání osobní schůzky je nutná předchozí telefonická či e-mailová domluva.<br>"}>
              </Text>

              <Title className="title-box" content={"Cena právních služeb"}>
              </Title>

              <Text className="text-box" content={"Úvodní konzultace (seznámení s právním problémem, sjednání podmínek zastupování) je zdarma.\n<br><br>Advokátní kancelář poskytuje právní služby za odměnu ve výši od 2 500 Kč bez DPH/hodinu. <br>Advokátní kancelář je plátcem DPH.&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17530/8dacf41cc271417fb38d91bd3e5cd44e_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17530/8dacf41cc271417fb38d91bd3e5cd44e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17530/8dacf41cc271417fb38d91bd3e5cd44e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17530/8dacf41cc271417fb38d91bd3e5cd44e_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"kontakt"} style={{"backgroundColor":"rgba(64, 64, 64, 1)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1--95);\">Kontaktujte nás</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":380}} content={"V případě zájmu o právní služby nás neváhejte kontaktovat prostřednictvím formuláře vpravo.<br><br>Odpověď na Vaši zprávu obdržíte nejpozději do druhého pracovního dne.&nbsp;<br><br>Kontaktovat nás můžete také na telefonním čísle +420 776 806 888 (Mgr. David Havel, vedoucí pobočky Hranice).<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1 --right" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit","align":"center"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--20" name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--26 swpf--uppercase" content={"Solers legal s.r.o.<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334}} content={"pobočka Hranice<br>Kunzova vila<br>Tř. 1. máje 328, Hranice<br>Česká Republika"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--26 swpf--uppercase" content={"Kontakt"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334}} content={"+420 776 806 888<br>david.havel@solers.legal<br>www.solers.legal<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" style={{"maxWidth":384}} content={"<span style=\"font-weight: bold;\">Informace pro klienty<br></span><br>Subjektem mimosoudního řešení spotřebitelských sporů ze smlouvy o poskytování právních služeb je Česká advokátní komora. Internetová adresa České advokátní komory je www.cak.cz."}>
              </Text>

              <Text className="text-box text-box--right" style={{"maxWidth":304}} content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}